<template>
  <el-form
    class="skif-tb-filters"
    :class="{ '--open': open, '--disable': disable, '--submited': submited }"
    @submit.prevent="search"
  >
    <div class="skif-tb-filters__cell">
      <el-select
        v-model="column"
        :placeholder="$t('reports.column')"
        size="small"
      >
        <el-option
          v-for="item in filteredColumns"
          :key="item.key"
          :label="item.name"
          :value="item.key"
        ></el-option>
      </el-select>
    </div>
    <div class="skif-tb-filters__cell">
      <el-select
        v-model="operation"
        :placeholder="$t('reports.operation')"
        size="small"
      >
        <el-option
          v-for="item in operations"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
    <div class="skif-tb-filters__cell">
      <el-input
        v-model="searchVal"
        :placeholder="$t('reports.value')"
        size="small"
        @keyup.enter.native="search"
      ></el-input>
    </div>
    <div class="skif-tb-filters__search-icon" @click="search">
      <searchIcon></searchIcon>
    </div>
    <div class="ml-5 flex items-center" @click="openColorDialog">
      <span
        v-if="Object.keys(colorCompanyItem).length"
        :style="{
          background: colorCompanyItem.color,
          width: '50px',
          height: '10px',
          cursor: 'pointer'
        }"
      ></span>
    </div>
    <div class="skif-tb-filters__reset-icon" @click="reset">
      <resetIcon></resetIcon>
    </div>
    <div class="skif-tb-filters__close-icon" @click="open = false">
      <closeIcon></closeIcon>
    </div>
    <div class="skif-tb-filters__refresh-icon" @click="refreshReport">
      <i class="el-icon-refresh-right"></i>
    </div>
    <el-dialog
      custom-class="companiesColor"
      :title="$t('reports.show-color')"
      :visible.sync="dialogColor"
      :modalAppendToBody="false"
    >
      <color-panel style="margin: 0 auto" v-model="colorValue"></color-panel>
      <div class="flex justify-end">
        <skif-button
          class="mt-1"
          style="width: fit-content; background: none; color: #5477a9"
          variant="normal"
          @click="checkedColor"
          >{{ $t('btn.select') }}</skif-button
        >
      </div>
    </el-dialog>
  </el-form>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
  name: 'ReportsTableFilters',
  components: {
    searchIcon: () => import('@/assets/svg-icons/search.svg'),
    resetIcon: () => import('@/assets/svg-icons/close.svg'),
    closeIcon: () => import('@/assets/svg-icons/arrow-left.svg')
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Object,
      default: () => ({})
    },
    colorDateValue: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      open: false,
      dialogColor: false,
      colorValue: '',
      column: '',
      operation: '',
      operations: [
        { value: 'eq', label: '=' },
        { value: 'lt', label: '<' },
        { value: 'gt', label: '>' },
        { value: 'like', label: this.$t('reports.contains') },
        { value: 'le', label: '<=' },
        { value: 'ge', label: '=>' },
        { value: 'ne', label: '!=' }
      ],
      searchVal: '',
      submited: false
    }
  },

  computed: {
    ...mapState('reports', {
      colorCompany: (state) => state.colorCompany,
      tableCurrentId: (state) => state.tableCurrentId
    }),
    ...mapGetters('reports', ['reportTableData']),
    colorCompanyItem() {
      let companyStyle
      this.colorCompany.forEach((item) => {
        if (
          item.id === this.tableData.unit_id &&
          item.type === this.tableData.report.key &&
          this.colorDateValue.from === item.from &&
          this.colorDateValue.to === item.to
        ) {
          companyStyle = item
        }
      })
      if (companyStyle) {
        return companyStyle
      }
      return {}
    },
    filteredColumns() {
      return this.columns.filter((item) => item.is_searchable)
    },
    disable() {
      return !this.open
        ? false
        : !(this.column && this.operation && this.trimSearchVal)
    },
    conditions() {
      let res = null
      if (!this.disable) {
        res = {
          field: this.column,
          value: this.trimSearchVal,
          operator: this.operation
        }
      }
      return res
    },
    trimSearchVal() {
      return this.searchVal ? this.searchVal.trim() : ''
    }
  },
  methods: {
    ...mapMutations('reports', ['SET_CURRENT_TABLE_DATA']),
    checkedColor() {
      if (this.tableData) {
        const color = {
          id: this.tableData.unit_id,
          color: this.colorValue,
          from: this.colorDateValue.from,
          to: this.colorDateValue.to,
          type: this.tableData.report.key
        }
        this.$store.dispatch('reports/COLOR_COMPANY', color)
        this.dialogColor = false
      }
    },
    openColorDialog() {
      this.dialogColor = true
      const data = {
        ...this.tableData,
        ...this.colorDateValue
      }
      this.SET_CURRENT_TABLE_DATA(data)
    },
    search() {
      if (!this.open) {
        this.open = true
        return
      }
      if (this.disable) {
        return
      }
      this.$emit('search', this.conditions)
      this.submited = true
    },
    reset() {
      this.$emit('search', null)
      this.submited = false
      this.column = ''
      this.operation = ''
      this.searchVal = ''
    },
    refreshReport() {
      this.$emit('refresh-report')
    }
  }
}
</script>

<style lang="stylus">
.one-color-stash, .one-color-present, .color-slider-pointer {
  display none !important
}
.one-colorpanel {
  box-shadow none !important
  width 200px !important
}
.one-color-value {
  border-bottom none !important
}
.one-color-slider {
  width 100% !important
}
.one-hue {
  cursor pointer
  margin-bottom 10px !important
  height 6px !important
}
.one-opacity {
  cursor pointer
  height 6px !important
}
.one-colors {
  height 170px !important
}
.skif-tb-filters
  display flex
  vertical-align center
  margin auto auto auto 20px
  &.--open
    .skif-tb-filters
      &__cell
        width 150px
        margin-left 3px
        margin-right 3px
      &__search-icon
         margin-left 5px
         transition margin-left 1s
      &__close-icon, &__reset-icon
        width 26px
        opacity 1
        transition width 0s, opacity 1.5s 1s
  &.--disable
    .skif-tb-filters
      &__search-icon
        opacity 0.7
        cursor default
  &.--submited
    .skif-tb-filters
       &__reset-icon
        display flex
  &__cell
    margin 10px 0
    width 0
    transition width 1s, margin 1s
    overflow hidden
  &__search-icon, &__close-icon, &__reset-icon, &__refresh-icon
    display flex
    background-color #EEF5FF
    margin auto
    width 26px
    height 26px
    border-radius 50%
    cursor pointer
    border 1px solid #EEF5FF
    transition border-color 0.3s
    &:hover
      border-color #91B4E7
    svg
      color #5477A9
      margin auto
      width 12px
      height 12px
  &__close-icon, &__reset-icon
    display flex
    width  0
    margin-left 5px
    opacity 0
    transition  width 0.1s 1.5s, opacity 1.5s
  &__reset-icon
    display none
    margin-left 5px
  &__refresh-icon
    align-items center
    justify-content center
    margin-left 10px

  @media screen and (max-width: 1230px)
    .skif-tb-filters
      &__cell
        max-width 120px
  @media screen and (max-width: 1150px)
    margin-left 5px
    .skif-tb-filters
      &__cell
        max-width 100px
        .el-input__inner
          padding-left 5px
          padding-riht 23px
          height 25px
          line-height 25px
          font-size 10px
</style>
